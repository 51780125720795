import { useEffect, useState } from "react";
import { useStyles } from "./PasswordChangeFormStyle";

import Layout from "layout/Layout";
import clsx from "clsx";
import ActionInput from "components/ActionInput/ActionInput";
import ActionButton2 from "components/ActionButton2/ActionButton2";
import SendIcon from "@mui/icons-material/Send";
import { ApiResetPasswordApply } from "Apis/ApiResetPasswordApply";
import { RES_STATUS_OK } from "config/constant";
import WarningIcon from "@mui/icons-material/Warning";

import { isEmpty } from "lodash";

export const PasswordChangeForm = () => {
  const classes = useStyles();

  const [apiWaitStatus, setApiWaitStatus] = useState(false);
  const [errMsg, setErrMsg] = useState<Array<String>>([]);
  const [resultMsg, setResultMsg] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [completed, setCompleted] = useState(false);

  const handleResetPassword = async () => {
    console.log("処理が来ている");
    if (!validate()) return;
    setApiWaitStatus(true);

    const response = await ApiResetPasswordApply(accountEmail, sendEmail);
    if (response["result-status"] === RES_STATUS_OK) {
      setErrMsg([]);

      setCompleted(true);
      setResultMsg(response["message"]);
    } else {
      setResultMsg("");
      setErrMsg([response["message"]]);
    }
    setApiWaitStatus(false);
  };

  const validate = () => {
    let errors = [];
    if (accountEmail.length == 0) {
      errors.push(
        "「管理者より案内を受けたログイン用メールアドレス」を入力してください"
      );
    }

    if (sendEmail.length == 0) {
      errors.push("「やり取りができるメールアドレス」を入力してください");
    }

    if (!/^[^@]+@[^@.]+.[^@]+$/.test(accountEmail)) {
      errors.push(
        "「管理者より案内を受けたログイン用メールアドレス」をアドレスの形式が正しくありません。"
      );
    }

    if (!/^[^@]+@[^@.]+.[^@]+$/.test(sendEmail)) {
      errors.push(
        "「やり取りができるメールアドレス」をの形式が正しくありません。"
      );
    }

    if (isEmpty(errors)) {
      return true;
    }

    setErrMsg(errors);

    return false;
  };

  return (
    <div className={classes.loaderWrapper}>
      <div className={classes.modalRoot}>
        <div className={classes.title}>パスワード再設定フォーム</div>
        <div className={classes.mainContainer}>
          {completed ? (
            <div className={clsx(classes.message, classes.resultMessage)}>
              {resultMsg}
            </div>
          ) : (
            <>
              <div
                className={clsx(
                  classes.resetPassword,
                  classes.resetPasswordDiscription
                )}
              >
                <WarningIcon />
                <div className={classes.descriptionBox}>
                  ①アカウントで使用しているログイン用メールアドレスをご入力ください。
                  <br />
                  ②パスワード再設定用のリンクをメールにて送信します。送信先アドレスをご入力ください。
                </div>
              </div>
              {!isEmpty(errMsg) && (
                <div className={clsx(classes.message, classes.errorMessage)}>
                  {errMsg.map((errorText, index) => {
                    return <div key={index}>・{errorText}</div>;
                  })}
                </div>
              )}
              <div className={clsx(classes.message, classes.resultMessage)}>
                {resultMsg}
              </div>
              <div className={classes.form}>
                <div className={classes.formTitle}>
                  ①管理者より案内を受けたログイン用メールアドレス
                </div>
                <ActionInput
                  value={accountEmail}
                  action={(e) => setAccountEmail(e)}
                  placeholder="account_email@fukuri.jp"
                  className={classes.formInput}
                />
              </div>
              <div className={classes.form}>
                <div className={classes.formTitle}>
                  ②再設定のやり取りができるメールアドレス
                </div>
                <ActionInput
                  value={sendEmail}
                  action={(e) => setSendEmail(e)}
                  placeholder="private_email@fukuri.jp"
                  className={classes.formInput}
                />
              </div>
              <div className={classes.btnContainer}>
                <ActionButton2
                  type="dark"
                  disable={apiWaitStatus}
                  action={handleResetPassword}
                  loading={apiWaitStatus}
                  endIcon={<SendIcon />}
                  content={apiWaitStatus ? "処理中" : "再設定用のリンクを送る"}
                  className={clsx(
                    classes.sendBtn,
                    apiWaitStatus && classes.loading
                  )}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
