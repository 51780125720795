import sidebar1 from "../assets/icons/sidebar-icon1.png";
import sidebar2 from "../assets/icons/sidebar-icon2.png";
import sidebar3 from "../assets/icons/sidebar-icon3.png";
import sidebar4 from "../assets/icons/sidebar-icon4.png";
import accountGray from "../assets/icons/mobileMenu/account_gray.png";
import contactGray from "../assets/icons/mobileMenu/contact_gray.png";
import flagBlack from "../assets/icons/flag-black.png";
import searchGray from "../assets/icons/mobileMenu/search_gray.png";
import usageBlack from "../assets/icons/usage-black.png";
import usageGray from "../assets/icons/mobileMenu/usage_gray.png";
import homeGray from "../assets/icons/mobileMenu/home_gray.png";

import cautionBlack from "../assets/icons/caution-black.png";

import homeGreen from "../assets/icons/mobileMenu/home_green.png";
import usageGreen from "../assets/icons/mobileMenu/usage_green.png";
import accountGreen from "../assets/icons/mobileMenu/account_green.png";
import contactGreen from "../assets/icons/mobileMenu/contact_green.png";
import searchGreen from "../assets/icons/mobileMenu/search_green.png";

export const LOGO_MAP = {
  searchPc: sidebar1,
  serviceForCompanyPc: sidebar2,
  usagePc: sidebar3,
  accountPc: sidebar4,
  homeGray: homeGray,
  homeGreen: homeGreen,
  searchGray: searchGray,
  searchGreen: searchGreen,
  usageGray: usageGray,
  usageGreen: usageGreen,
  contactGray: contactGray,
  contactGreen: contactGreen,
  accountGray: accountGray,
  accountGreen: accountGreen,
  flagBlack: flagBlack,
  usageBlack: usageBlack,
  cautionBlack: cautionBlack,
};

export const MOBILE_MENU_THRESHOLD_WIDTH = 839;

export const REQUEST_API_URL = process.env.REACT_APP_REQUEST_API_URL;
export const REQUEST_IMG_URL = process.env.REACT_APP_REQUEST_IMG_URL;
export const REQUEST_AVATAR_IMG_URL =
  process.env.REACT_APP_REQUEST_AVATAR_IMG_URL;

export const RES_STATUS_NOT_LOGIN = 401;
export const RES_STATUS_OK = 200;

export const SEARCH_DIR_NAME = "search";
export const POPULAR_DIR_NAME = "popular";
export const CATEGORY_DIR_NAME = "category";
export const SERVICES_DIR_NAME = "services";
export const ROOT_LINK = "/";
export const ACCOUNT_LINK = "/account";
export const ACCOUNT_SETTING_LINK = "/account/setting";
export const USAGE_LINK = "/usage";
export const CONTACT_LINK = "/usage/contact";
export const PATH_DEFINITIVE_REGISTRATION = "/definitive_registration/:token";
export const PATH_RESET_PASSWORD = "/reset_password/:token";
export const BUSINESS_EMAIL_CHANGE_LINK = "/business_email_change";
export const PASSWORD_RESET_FORM_LINK = "/reset_password";
export const PRIVATE_EMAIL_CHANGE_LINK = "/private_email_change";
export const MAP_LINK = "/map";
export const FAMILY_REGISTRATION = "/invitations";
export const FAMILY_REGISTRATION_FINISH = "/invitations/complete";

//export const CONTACT_KARI_LINK = "https://shisaku-pad.co.jp/contact/inquiry";
export const SERVICE_APPLICATIONS_COMPLETE_PAGE_LINK =
  "/service_applications/thanks";
export const CONTACT_COMPLETE_PAGE_LINK = "/contact/thanks";

export const USAGES_TYPE_ID_CLIENT_USER = 1;
export const USAGES_TYPE_ID_CLIENT_ADMIN = 2;

export const SHISAKU_PAD_PRIVACY_POLICY_NAME = "株式会社Leafea";
export const PRIVACY_POLICY_URL = "https://leafea.co.jp/privacy-policy";
export const COMPANY_ABOUT_URL = "https://leafea.co.jp/company";
export const COMPANY_TERM_URL = "https://leafea.co.jp/terms";
export const TICKET_URL = "https://leafea.co.jp/app/tickets";
export const SCTA_URL = "https://leafea.co.jp/scta";

export const SERVICE_NAME = "建設サイト福利";
export const COPYRIGHT = "©︎ Leafea Inc. All Right Reserved.";

export const IOS_NAVITVE_LINL =
  "https://apps.apple.com/app/6451134527";
export const ANDROID_NAVITVE_LINL =
  "https://play.google.com/store/apps/details?id=jp.shisaku_pad.app";

export const SHISAKU_PAD_LANDING_VIMEO_ID = "854294486";
export const SHISAKU_PAD_LANDING_SP_VIMEO_ID = "817907442";

export const QUESTION_TYPE_ID_FROM_SECURE_SITE = 1;
export const QUESTION_TYPE_ID_FROM_CLIENT_SITE = 2;

export const IMAGES_TYPE_ID_AVATAR = "1";
export const IMAGES_TYPE_ID_SERVICE_LOGO = "2";
export const IMAGES_TYPE_ID_SERVICE_MAIN = "3";
export const IMAGES_TYPE_ID_SERVICE_FREE_EDIT = "4";
export const IMAGES_TYPE_ID_SITE = "5";
export const IMAGES_TYPE_ID_SERVICE_CASSETTE = "6";
export const IMAGES_TYPE_ID_USAGE = "7";

export type SIDEBAR_FUNCTIONS =
  | "search"
  | "service"
  | "contact"
  | "usage"
  | "account"
  | "home"
  | "usageMobile";

export const SIDEBAR_ID_FUNC_LIST: SIDEBAR_FUNCTIONS[] = [];
SIDEBAR_ID_FUNC_LIST[1] = "search";
SIDEBAR_ID_FUNC_LIST[2] = "service";
SIDEBAR_ID_FUNC_LIST[3] = "usage";
SIDEBAR_ID_FUNC_LIST[4] = "account";
SIDEBAR_ID_FUNC_LIST[5] = "home";
SIDEBAR_ID_FUNC_LIST[6] = "usageMobile";
SIDEBAR_ID_FUNC_LIST[7] = "contact";

export const SidebarData = [
  {
    id: SIDEBAR_ID_FUNC_LIST.indexOf("usage"),
    img: sidebar3,
    content: "利用方法",
    link: "",
    router: {
      show: true,
      routers: [
        {
          name: "利用方法",
          link: USAGE_LINK,
        },
        {
          name: "お問い合わせ",
          link: CONTACT_LINK,
        },
      ],
    },
    sort_key: 100,
    use_mobile: false,
    use_pc: true,
  },
  {
    id: SIDEBAR_ID_FUNC_LIST.indexOf("account"),
    img: sidebar4,
    content: "アカウント",
    link: "",
    router: {
      show: false,
      link: ACCOUNT_LINK,
    },
    sort_key: 110,
    mobile_caption: "マイページ",
    use_mobile: true,
    use_pc: true,
  },
  {
    id: SIDEBAR_ID_FUNC_LIST.indexOf("home"),
    img: sidebar3,
    content: "home",
    link: "",
    router: {
      show: false,
      link: ROOT_LINK,
    },
    sort_key: 1,
    mobile_caption: "home",
    use_mobile: true,
    use_pc: false,
  },
  {
    id: SIDEBAR_ID_FUNC_LIST.indexOf("usageMobile"),
    img: sidebar3,
    content: "利用方法",
    link: "",
    router: {
      show: false,
      link: USAGE_LINK,
    },
    sort_key: 101,
    mobile_caption: "利用方法",
    use_mobile: true,
    use_pc: false,
  },
  {
    id: SIDEBAR_ID_FUNC_LIST.indexOf("contact"),
    img: sidebar3,
    content: "お問い合わせ",
    link: "",
    router: {
      show: false,
      link: CONTACT_LINK,
    },
    sort_key: 101,
    mobile_caption: "お問い合わせ",
    use_mobile: true,
    use_pc: false,
  },
];
